
@import 'navbar';
@import 'footer';
@import 'auth';
@import 'mainpage';
@import 'offers';
@import 'blog';
@import 'adminblog';

$primary:#49a6e9;
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $primary #ffffff;
    
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
.content{
  background-color: #f5f5f7;
  position: absolute;
  height: 100%;
  width: 100%;
  display: table;
}
  /* Offcanvas !important */
  .offcanvas{
    max-width: 70% !important;
    bottom: 30% !important;
    border-radius: 10px;
    margin: 10px;
    font-size: 1.1rem;
    background-color: rgba(0,0,0,0.7) !important;
    color: #fff !important;
  }
  .offcanvas-title {
    color: #fff !important;
  }
  .offcanvas-body{
    color: #fff;
    font-size: 1.1rem;
    margin-left: 5px;
    font-weight: 500;
  }
  .main__home{
    @media (max-width: 768px){
     margin-left: auto !important;
  }
  }
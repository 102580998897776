.navbar {
  position: relative;
  padding: 16px 8px 8px 8px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E4E8F0;
  @media (min-width: 800px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  & .navbar__logo{
    text-decoration: none;
    color: black;
  }
}

.navbar__logo {
  width: 148px;
  height: 28px;
}

.navbar__links {
  position: absolute;
  left: calc(50% - 400px / 2);
  width: 400px;
  display: none;
  justify-content: space-between;

  @media (min-width: 800px) {
    display: flex;
  }

  & a {
    width: 88px;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #49a6e9;
    opacity: 0.6;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }

  & input {
    width: 88px;
    height: 100%;
    color: #49a6e9;
    opacity: 0.6;
  }
}

.navbar__icon {
  display: none;
  cursor: pointer;
  color: #49a6e9; 
  @media (min-width: 800px) {
    display: flex;
  }

  & svg {
    color: #49a6e9;
    margin-left: 8px;
    margin-right: 8px;
    width: 25px;
    height: 25px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.navbar__menu {
  color: #49a6e9;
  height: 50px;

  @media (min-width: 800px) {
    display: none;
  }

  & svg {
    color: #49a6e9;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .sidebar__button{
    border: none;
    background: none;
  }
}

.sidenav {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  background: #ffffff;
  text-align: center;
}

.sidenav__element {

  & a {
    width: 88px;
    font-family: sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #49a6e9;
    opacity: 0.6;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }
}
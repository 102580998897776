#editor {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

#toolbar {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

#toolbar button {
    font-weight: bold;
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 5px;
}

#content {
    min-height: 200px;
    border: 1px solid #ccc;
    padding: 10px;
}

.component {
    border-radius: 10px !important;
    border: aliceblue 1px solid !important;
    text-align: center;
    cursor: grab;
    margin-bottom: 0.25rem;
}

.component:hover {
    background-color: #f5f5f5;
}
.page {
    border: 1px solid #ddd;
    min-height: 200px;
    margin: 20px;
    padding: 10px;
  }

  .block {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    cursor: grab;
  }

  .block:hover {
    background-color: #f5f5f5;
  }

  .dragging {
    opacity: 0.5;
  }
  .popup {
    display: none;
}


.cms {
    display: flex;
    height: 100vh;
  }
  
  .component-palette {
    flex: 0 0 200px;
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .component-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .component {
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    cursor: move;
  }
  
  .canvas {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  
  #intellisense {
    position: absolute;
    z-index: 1;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #d17373;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    line-height: 1.5;
    display: none;
  }
  
  #intellisense li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 9rem;
  }
  
  #intellisense li {
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  #intellisense li:hover {
    background-color: #ccc;
  }
  .selected {
    background-color: red;
}

.sidebar {
  height: 100%;
  position: fixed;
  top: 10px;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  & li:hover{
    background-color: #f5f5f5;
    border-radius: 14px;
    border: 0px;
  }
}
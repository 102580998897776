$primary:#49a6e9;
.offers {
    & .row {
        @media (min-width: 768px){
            display: flex;
            flex-direction: row-reverse;
        }
    }
    & .offers__information{
        position: sticky;
        top: 0;
        height: 800px;
        overflow-y: auto;
        @media (max-width: 768px){
            display: none;
        }
        
    }
}
.offers__card h1{
    font-size: 21px;
    letter-spacing: -0.3px;
    font-weight: 300;
    margin-bottom: 3px;
}
.offers__card{
    cursor: pointer;
    & a {
        text-decoration: none;
    }
    & .offers__card-shadow-box {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        background: #FFFFFF;
    }
}
.offers__card-content   {
    padding: 20px 20px 16px 20px;
    & .offers__card-links {
        float: left;
        margin-right: 20px;
    }
    & .offers__card-image{
        max-width: 126px;
        line-height: 0px;
        margin-bottom: 5px;
        text-align: center;
        & a {
            border: 2px solid #f5f5f5;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            display: block;
            &:hover {
                border-color: $primary;
            }

        }
        &  img {
            width: 100%;
        }
    }
}

.offers__card-shophead .offers__card-title {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: -2px;
    line-height: 1.5;
}

.offers__card-shophead .offers__card-description {
    font-size: 13px;
    line-height: 1.7;
    position: relative;
}
.offers__card-offert {
    & .offers__card-offert-check {
        line-height: 1;
        padding: 14px 38px;
        background: #fc8c99;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        letter-spacing: 1px;
        text-transform: uppercase;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        margin-bottom: 2px;
       
        text-align: center;
    }
    & .offers__card-offert-date {
        font-size: 13px;
        display: block;
        margin-top: 5px;
        margin-bottom: -2px;
        color: #888888;
    }
}
.offers__create{
    & .container {
        @media (min-width: 1400px){
            max-width: 1140px;
        }
    }
    & .offers__card-links {
        float:none;
    }
    & .offers__card-offert {
        & .offers__card-offert-check {
            @media (max-width: 400px){
                width: 100%;
            }
        }
    }
}

$primary: #49a6e9;

.searchbox {
    display: inline-flex;
    width: 100%;
    padding: 3px 10px;
    background-color: white;
    border-radius: 20px;

    & .searchbox__button {
        border: 0;
        display: inline-flex;
        align-items: center;
        padding: 3px;
        cursor: default;
        background-color: transparent;
        color: $primary;
    }

    & .searchbox__input {
        outline: none;
        color: black;
        border: 0;
        height: 1.1876em;
        padding: 6px 0 7px;
        box-sizing: content-box;
        flex-grow: 1;
        text-overflow: ellipsis;
        opacity: 1;
        padding-left: 5px;
        min-width: 30px;
    }
}

.countrybox {
    display: inline-flex;
    width: 100%;
    padding: 3px 10px;
    background-color: white;
    border-radius: 20px;
    transition: 6s;

    & .countrybox__dropdown {
        border: 0;
        display: inline-flex;
        align-items: center;
        padding: 3px;
        cursor: default;
        background-color: transparent;
        color: $primary;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;

    }
}

.dropdown-item{
    cursor: pointer;
}
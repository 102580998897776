.signup-login {
    margin-top: 7rem;
    margin-bottom: 10rem;
    border-radius: 5px;
    width: 100%;
    padding: 1.755em;

    & .signup-login__form {
        background-color: #fff;
        background: radial-gradient(circle, #fff, #f2f2f2, #5650504f 0%);
        border-radius: 5px;
        position: relative;
        opacity: 1;
    }

    & h1 {
        padding: 5px 0;
        font-size: 1.4rem;
        font-weight: normal;
        text-align: center;
        color: #111;
    }

    & form {
        max-width: 450px;
        margin: 10px auto;
        padding: 0.5rem 1rem;
    }

    @media (max-width: 800px) {
        max-width: 90%;
    }
}

.signup-login-others_field {
    margin-bottom: 0.75rem;

    & label {
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: top;
        width: 100%;
    }

    & input {
        border: solid 1px #ccc;
        border-radius: 3px;
        display: inline-block;
        padding: 0.5rem 0.75rem;
        width: 100%;
        font-family: inherit;
        font-size: 1rem;
    }

    :focus {
        outline: none;
        box-shadow: 0 0 0 2pt rgb(49, 132, 253, 0.5);
    }

    & input[disabled] {
        background-color: #f5f5f5;
        box-shadow: none;
        color: #7a7a7a;
        cursor: not-allowed;
    }

    & button {
        background: #0d6efd;
        color: #fff;
        border-radius: 0.25rem;
        font-size: 1rem;
        font-weight: 400;
        border: 1px solid #0d6efd;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        text-align: center;
        padding: 0.375rem 0.75rem;
    }

    & button:hover {
        background: #0b5ed7;
        border-color: #0a58ca;
        cursor: pointer;
    }

}

.relative {
    .absolute {
        width: 100%;
        position: relative;
        display: grid;
        padding: 1rem;

        img {
            max-width: 100%;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            vertical-align: middle;
            position: absolute;
            width: 100%;
            height: 100%;
            filter: invert(100);
            opacity: 0.33;
            border-radius: 10px;
        }
    }
}
.blog {
    .blog__header{
        width: 100%;
        position: relative;
        padding: 8rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 360px;
        & .blog__header-img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        & .blog__header-text {
            width: 60%;
            z-index: 1;
            text-align: center;
            color: #fff;
            @media (max-width: 768px){
                width: 95%;
            }
        }
        @media (max-width: 768px){
            padding: 2rem 0;
            min-height: 180px;
        }
        img {
            -o-object-fit: cover;
            object-fit: cover;
            max-width: 100%;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            vertical-align: middle;
        }
    }
    & .blog__header.shadow::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #263238;
        opacity: 0.7;
    }
}
